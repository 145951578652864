.IconButton_main {
  cursor: pointer;
  height: 40px;
  min-width: 40px;
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.IconButton_main img {
  height: 20px;
  width: 20px;
}

.IconButton_main .IconButton_main_svg {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconButton_main .IconButton_main_svg svg {
  height: 100%;
}

.IconButton_main:hover {
  background: rgba(0, 108, 255, 0.1);
  border: none;
}

.IconButton_main_outline {
  border: none;
}

.IconButton_main .IconButton_main_svg svg path {
  fill: #006cff;
}
.IconButton_main_outline .IconButton_main_svg svg path {
  fill: rgba(0, 0, 0, 0.38);
}

.IconButton_main_outline:hover .IconButton_main_svg svg path {
  fill: #006cff;
}
.IconButton_main_disabled{
  background: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: inherit;
}
.IconButton_main_disabled:hover{
  background: inherit;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: inherit;
}