.signup_quiz_component {
  margin: 0 auto;
  max-width: 825px;
  font-family: var(--main-font);

}
.signup_quiz_sequence_selector_description_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signup_quiz_sequence_selector_description {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
}
.signup_quiz_sequence_selector_question_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup_quiz_sequence_selector_question {
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 52px;
  text-align: center;
}
.signup_quiz_sequence_selector_answers_wrapper {
  display: flex;
  width: 534px;
  margin: 43px 0px 0px 0px;

  justify-content: center;
  align-items: center;
}
.signup_quiz_mult_buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto;
  width: 587px;
}
.signup_quiz_single_button_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  width: 587px;
}
.signup_quiz_button .bt-span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.signup_quiz_sequence_selector_notification_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup_quiz_sequence_selector_notification {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}
@media only screen and (max-width: 750px) {
  .signup_quiz_sequence_selector_answers_business_sequence_step_2_wrapper {
    flex-direction: column !important;
  }
  .signup_quiz_sequence_selector_answer_card {
    margin-bottom: 20px !important;
  }
}
