.svgClass svg {
  width: 20px;
  max-height: 30px;
}

.centerClass {
  display: flex;
  justify-content: center;
  /* z-index: 101; */
}
