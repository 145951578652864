.titled_input_wrapper {
  font-family: var(--main-font);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 10px auto;
}
.titled_input_title {
  position: relative;
  background: white;
  width: fit-content;
  left: 10px;
  padding: 0 1px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 12px;
  transform: translateY(50%);
}
.titled_input_input {
  width: 100%;
  height: 38px;
  outline: none;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  padding: 9px 35px 9px 12px;
  border: 1px solid rgba(43, 43, 43, 0.1);
  border-radius: 4px;
}
.titled_input_symLength {
  position: absolute;
  background: white;
  width: fit-content;
  right: 10px;
  padding: 0 1px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  line-height: 12px;
  /* transform: translateY(50%) */
}
.titled_input_textarea {
  width: 100%;
  min-height: 90px;
  color: #000000;
  font-family: var(--main-font);
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
  outline: none;
  height: auto;
  padding: 5px 10px;
}
.titled_input_content_wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.titled_input_prefix_wrapper {
  margin-right: 10px;
}
