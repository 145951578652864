.AppNavProgressBar_test_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px;
}

.AppNavProgressBar_test_wrapper span {
  color: #ffffff;
  font-family: var(--main-font);
  font-size: 12px;
}

.AppNavProgressBar_wrapper {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.AppNavProgressBar_current {
  height: 36px;
  width: 36px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-family: var(--main-font);
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d1d33;
  z-index: 5;
  border-radius: 50%;
}

.AppNavProgressBar_left {
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.AppNavProgressBar_right {
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.AppNavProgressBar_left div {
  background: #006cff;
  position: absolute;
  width: 200%;
  height: 100%;
  transform: rotate(180deg);
  left: -100%;
  transform-origin: right;
  transition: 0.4s;
  transition-delay: 0.32s;
}

.AppNavProgressBar_right div {
  background: #006cff;
  position: absolute;
  width: 200%;
  height: 100%;
  transform: rotate(180deg);
  right: -100%;
  transform-origin: left;
  transition: 0.4s;
}
