.pricingRangeComponent-container {
  margin: 0 auto;
  width: 100%;
  /* max-height: 390px; */
  font-family: "SF UI Text Regular";
}

.pricingRangeComponent-tabs-section {
  width: 100%;
  text-align: center;
  margin-top: 29px;
}

.currentPlanPointer-arrow {
  margin-left: -5px;
}

.currentPlanPointer-arrow-reversed {
  position: absolute;
  transform: scaleX(-1);
  left: -13px;
}

.currentPlanPointer-reversed {
  width: 110px;
  height: 11px;
  color: #1377ff;
  font-family: "SF UI Text Regular";
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -5px;
  left: -20px;
  transform: translateX(-100%) translateY(-50%);
}

.currentPlanPointer-text {
  /* Style for "Your curre" */
  width: 110px;
  height: 11px;
  color: #1377ff;
  font-family: "SF UI Text Regular";
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -5px;
  left: 20px;
}

.pricingRangeComponent-tabs-section-singe-tab {
  font-family: inherit;
  width: 89px;
  border-bottom: 1px solid #f2f2f2;
  padding: 11px 20px;
  color: #212121;
  font-size: 12px;
  font-weight: 700;
  line-height: 36px;
}

.pricingRangeComponent-tabs-section-singe-tab-selected {
  font-family: inherit;
  width: 89px;
  border-bottom: 2px solid #006cff;
  padding: 11px 20px;
  color: #212121;
  font-size: 12px;
  font-weight: 700;
  line-height: 36px;
}

.pricingRangeComponent-info {
  width: 100%;
  margin: 39px auto;
  color: #000000;
  font-family: inherit;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.pricingRangeComponent-range-slider-container {
  margin: 46px auto;
  max-width: 630px;
  position: relative;
}

.pricingRangeComponent-range-slider-input-progress-conteiner {
  display: flex;
  max-width: 630px;
  background: #d3d3d3;
  pointer-events: none;
  position: relative;
  top: 14px;
  border-radius: 4px;
}

.pricingRangeComponent-range-slider-input {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  /* overflow: hidden; */
}

.pricingRangeComponent-range-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  align-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("./sliderThumb.png") no-repeat center;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.pricingRangeComponent-range-slider-input::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  align-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("./sliderThumb.png") no-repeat center;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.pricingRangeComponent-range-slider-input-progress-color {
  width: 0%;
  /* margin-left: 10px; */
  height: 8px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #006cff 0%, #00bfff 100%);
  position: relative;
}

.pricingRangeComponent-range-slider-input::-webkit-slider-runnable-track {
  background: transparent;
}

.pricingRangeComponent-range-slider-input-progress-thumb {
  width: 20px;
  height: 20px;
  overflow: hidden;
  display: flex;
  background: white;
  align-items: center;
  justify-content: center;
  position: absolute;

  border-radius: 50%;
  border-radius: 50%;
  top: -6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.pricingRangeComponent-range-slider-list {
  margin-top: 22px;
  max-width: 630px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.pricingRangeComponent-range-slider-list-item {
  color: #212121;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  /* flex: 1; */
  text-align: center;
  position: absolute;
}

.pricingRangeComponent-range-slider-list-item::before {
  content: "|";
  position: absolute;
  top: -20px;
  left: 8px;
}

.myRange {
  margin-top: 10px;
  width: 100%;
  position: absolute;
  top: -2px;
  user-select: none;
  pointer-events: none;
}

.myRange-slider {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  /* background: #d3d3d3; */
  padding: 0 10px;
}

.myRange-slider-thumb {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-image: linear-gradient(to right, #006cff 0%, #00bfff 100%);
  position: relative;
}

.myRange-slider-thumb-background {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: #d3d3d3;
  margin-bottom: -8px;
}

.img-tumb {
  position: absolute;
  height: 20px;
  width: 20px;
  display: flex;
  padding: 0px 5px;
  border-radius: 50%;
  /* padding: 5px 0px 0px 0px; */
  overflow: hidden;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(180deg, #006CFF 0%, #00BFFF 100%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  right: 0;
  transform: translateX(10px);
  top: -7px;
}

.img-tumb img {
  height: 48px;
}

.img-thumb-pause1 {
  position: absolute;
  height: 40% !important;
  background: #ffffff;
  width: 2.3px !important;
  max-width: 2.3px !important;
}

.img-thumb-pause2 {
  position: absolute;
  height: 40% !important;
  width: 2.3px !important;
  max-width: 2.3px !important;
  background: #ffffff;
}

.CustomRange {
  position: relative;
  height: 70px;
  margin: 0 auto;
}

.myRange-scale {
  min-height: 10px;
  /* background: red; */
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  margin-top: 10px;
}

.scale-d {
  position: absolute;
  height: 100%;
  width: 1px;
  background: black;
}

.myRange-scale-wrap {
  width: 100%;
  position: relative;
}

.scale-d2 {
  position: absolute;
  transform: translateX(-50%);
  color: #212121;
  font-family: inherit;
  font-size: 12px;
  font-weight: 300;
}

.scale-d3 {
  top: -30px;
  left: 100%;
  font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

@media (max-width: 616px) {
  .CustomRange {
    position: relative;
    transform: rotate(270deg);
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .scale-d2 {
    transform: translateX(-50%) rotate(90deg);
  }

  .pricingRangeComponent-container {
    width: 300px;
  }
}

.pricingRangeComponent-summary-section {
  width: 100%;
  text-align: center;
  margin: 20px auto;
}

.pricingRangeComponent-summary-simple {
  font-size: 18px;
  font-family: inherit;
  font-weight: 200;
}

.pricingRangeComponent-summary-highlighted {
  color: #006cff;
  font-family: inherit;
  font-weight: 600;
}

.pricingRangeComponent-button-conteiner {
  width: 100%;
  text-align: center;
  margin: 40px 0;
}

.pricingRangeComponent-button {
  padding: 9px 16px;
  color: #ffffff;
  font-family: inherit;
  font-weight: 300;
  font-size: 16px;
  border-radius: 4px;
  background-color: #006cff;
  cursor: pointer;
  font-weight: bold;
}

.pricingRangeComponent-button-cancel {
  background: #ff6f61;
}

.customRange-wrapper {
  width: 640px;
  margin: auto;
}

.pricingRangeComponent-button-conteiner-iframe {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  width: 338px;
  background-color: #006cff;
  height: 50px;
  background-color: #006cff;
  border-radius: 3px;
  transition: all 0.5s;
  color: #fff;
}

.pricingRangeComponent-button-conteiner-iframe > span {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.pricingRangeComponent-button-conteiner-iframe:hover {
  color: #006cff;
  transform: scale(1.1);
  border-style: solid;
  border-color: #006cff;
  background: #fff;
  cursor: pointer;
}

.pricingRangeComponent-trial-info {
  display: flex;
    max-width: 700px;
    justify-content: space-around;
    margin: 20px auto;
}

.pricingRangeComponent-trial-info-span {
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 200;
}

.pricingRangeComponent-trial-info-check {
  color: #006cff;
}
