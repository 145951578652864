.CreateAccount_Checkbox_container {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
  background: #eee;
  cursor: pointer;
  border-radius: 3px;
}
.CreateAccount_item_checkbox {
  width: 16px;
  height: 16px;
  background-color: #006cff;
  position: absolute;
  opacity: 1;
  cursor: pointer;
}

.CreateAccount_Checkbox_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  border:  1px solid rgba(0, 0, 0, 0.54);
}

.CreateAccount_Checkbox_check {
  font-size: 10px;
  color: #fff;
  opacity: 0;
}

.CreateAccount_Checkbox_container
  input:checked
  ~ .CreateAccount_Checkbox_checkmark {
  background-color: #006cff;
  border: none;
}

.CreateAccount_Checkbox_container
  input:checked
  ~ .CreateAccount_Checkbox_checkmark
  .CreateAccount_Checkbox_check {
  opacity: 1;
}
