.InputComponent_main .InputComponent_mainInput {
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #ffffff;
  padding: 0 10px;
  outline: none;
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 200;
  width: 100%;
}

.InputComponent_main .InputComponent_mainInput:read-only {
  background: rgba(0, 0, 0, 0.02);
}

.InputComponent_main {
  width: 100%;
}
