.BotsStatsComponent_main {
  /* Style for "Прямоуголь" */
  flex: 1;
  height: 247px;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-right: 20px;
}

.BotsStatsComponent_main:last-child {
  margin-right: 0px;
}

.BotsStatsComponent_header {
  display: flex;
  align-items: center;
}
.BotsStatsComponent_header_name {
  display: flex;
  align-items: center;
  flex: 1;
}

.BotsStatsComponent_header_name span {
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 500;
}

.BotsStatsComponent_header_settings {
  display: flex;
  align-items: center;
}

.BotsStatsComponent_header_settings_button {
  cursor: pointer;
  /* Style for "Прямоуголь" */
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BotsStatsComponent_content {
  flex: 1;
  margin-top: 25px;
  background: #80808024;
}
