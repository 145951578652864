.pNotificationComponent_fixed {
  position: fixed;
  right: -430px;
  top: 0;
  padding: 12px;
  display: flex;
  transition: all 0.3s;
  transition-timing-function: ease-in-out;
  z-index: 27001;
}

.pNotificationComponent_fixed_show {
  right: 0px;
}

.pNotificationComponent_main {
  max-width: 400px;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  transition: all 0.3s;
}

.pNotificationComponent_container {
  display: flex;
  align-items: center;
  padding: 15px;
}

.pNotificationComponent_header {
  display: flex;
  justify-content: flex-end;
  /* padding-top: 5px; */
  min-height: 14px;
  align-items: center;
  padding-right: 10px;
  margin-left: -5px;
}

.pNotificationComponent_close_button {
  cursor: pointer;
}

.pNotificationComponent_close_button {
  padding: 5px;
  border-radius: 4px;
  display: flex;
  transition: all 0.3s;
  align-items: center;
}

.pNotificationComponent_close_button:hover {
  background: rgba(0, 0, 0, 0.1);
}

.pNotificationComponent_message {
  /* padding: 15px; */
  font-family: var(--main-font);
  color: white;
  font-weight: 300;
}

.pNotificationComponent_image {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background: rgba(0, 0, 0, 0.1);
}
