* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body,
#root {
  height: 100%;
  width: 100%;
  --main-font: "Rubik", sans-serif;
  /* --main-font-nums: "Roboto", sans-serif; */
  font-family: "Rubik", sans-serif;
  --main-color: #006cff;
  display: flex;
  flex-direction: column;
}

#root::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "SFUI";
  font-style: normal;
  font-weight: 200;
  src: local("SF UI Text Regular"),
    url("./font/SFUIText-Regular.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: italic;
  font-weight: 200;
  src: local("SF UI Text Italic"),
    url("./font/SFUIText-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: normal;
  font-weight: 100;
  src: local("SF UI Text Light"),
    url("./font/SFUIText-Light.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: italic;
  font-weight: 100;
  src: local("SF UI Text Light Italic"),
    url("./font/SFUIText-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: normal;
  font-weight: 300;
  src: local("SF UI Text Medium"),
    url("./font/SFUIText-Medium.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: italic;
  font-weight: 300;
  src: local("SF UI Text Medium Italic"),
    url("./font/SFUIText-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: normal;
  font-weight: 400;
  src: local("SF UI Text Semibold"),
    url("./font/SFUIText-Semibold.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: italic;
  font-weight: 400;
  src: local("SF UI Text Semibold Italic"),
    url("./font/SFUIText-SemiboldItalic.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: normal;
  font-weight: 500;
  src: local("SF UI Text Bold"), url("./font/SFUIText-Bold.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: italic;
  font-weight: 500;
  src: local("SF UI Text Bold Italic"),
    url("./font/SFUIText-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: normal;
  font-weight: 600;
  src: local("SF UI Text Heavy"),
    url("./font/SFUIText-Heavy.woff") format("woff");
}

@font-face {
  font-family: "SFUI";
  font-style: italic;
  font-weight: 600;
  src: local("SF UI Text Heavy Italic"),
    url("./font/SFUIText-HeavyItalic.woff") format("woff");
}

.ac_scroll::-webkit-scrollbar {
  width: 7px;
}

.ac_scroll::-webkit-scrollbar-thumb {
  border-radius: 7px;
  border-width: 1px 1px 1px 2px;
  border-color: #777;
  background-color: #aaa;
}

.ac_scroll::-webkit-scrollbar-thumb:hover {
  border-width: 1px 1px 1px 2px;
  border-color: #555;
  background-color: #777;
}

.ac_scroll::-webkit-scrollbar-track {
  border-width: 0;
}

.ac_scroll::-webkit-scrollbar-track:hover {
  background-color: #eee;
}
.a_tag_clear_default_styles {
  text-decoration: none;
}
.skill_editor_locker_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}
.skill_editor_locker_header {
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #212121;
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
}
.skill_editor_locker_header_title {
  margin-right: 10px;
}
.skill_editor_locker_toggle {
  color: #212121;
  font-family: var(--main-font);
  font-size: 12px;
  font-weight: 500;
  margin: 0 20px;
  display: flex;
}

.skill_editor_locker_icon {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.skill_editor_locker_link {
  font-weight: 500;
  font-size: 10px;
  color: #999999;
  margin-left: 5px;
  text-decoration: underline;
}
.skill_editor_locker_restore_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skill_editor_locker_restore_info {
  flex: 1;
  font-weight: 500;
  font-size: 9px;
}
.skill_editor_locker_restore_button {
  flex: 1;
  margin-left: 35px;
}
#jsd-widget {
  left: 5px;
}
.loading_component_wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  margin-top: 100px;
}
.loading_component {
  transform: translateX(-50%);
}

@media (max-width: 414px) {
  .skill_editor_locker_wrapper {
    padding: 5px;
  }
}
.build_in_nlp_disconected {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}
.build_in_nlp_disconected_text {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.00615385em;
  color: #757575;
}
.build_in_nlp_disconected_link {
  color: var(--main-color);
}
