.navbatlink {
  text-decoration: none;
  padding: 20px 0;
  width: 100%;
}

.navbatlink:hover span {
  color: #ffffff;
  text-decoration: underline;
}

.navbatlink:hover svg {
  fill: #ffffff;
}

.navbatactivelink {
  background-color: #193966;
}
.navbatactivelink span {
  color: #ffffff;
}

.navbatlink svg {
  fill: #9e9e9e;
}

.navbatactivelink svg {
  fill: #ffffff;
}

.whiteStyleButton span {
  color: #ffffff;
}

.whiteStyleButton svg {
  fill: #ffffff;
}

.GrayStyleButton span {
  color: #9e9e9e;
}
.GrayStyleButton svg {
  fill: #9e9e9e;
}

.custom_progressBar {
  padding: 20px 0;
}

.whiteStyleButton {
  padding: 20px 0;
}

.GrayStyleButton {
  padding: 70px 0;
}
