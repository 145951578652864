.CreateAccount_main {
  max-width: 460px;
  margin: 0 auto;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.LoginComponent_main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CreateAccount_title_header {
  color: #000;
  opacity: 0.87;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
}

.LoginComponent_main header {
  width: 100%;
  /* height: 63px; */
  display: flex;
  align-items: center;
  padding: 40px;
}

.LoginComponent_main header img {
  height: 44px;
}

.CreateAccount_container {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.CreateAccount_title_signup {
  color: #000;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
  padding: 5px 0;
}

.CreateAccount_title_signup a {
  color: #000;
}

.loginWithGoogle {
  margin: 20px 0;
}

.use_email_pass {
  font-family: var(--main-font);
  font-size: 12px;
  color: #000;
  margin-bottom: 20px;
  font-weight: 200;
}

.login {
  /* width: 50px; */
}

.emailInputContainer {
  margin-bottom: 20px;
  flex: 1;
}

.CreateAccount_input_title {
  font-family: var(--main-font);
  font-size: 12px;
  color: #000;
  margin-bottom: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  display: inline-block;
}

.emailInputContainer a {
  margin-bottom: 10px;
  color: #000;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
}
